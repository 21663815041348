
import * as React from "react"
import { Formik,Field,Form } from 'formik';


const EnterpriseForm = () => {

    const[companyType,setCompanyType]= React.useState('')
    const [sended, setSended] = React.useState(false);

  const createEnterprise = (values) =>{
        console.log(companyType)
        const _inquiry = {}
        _inquiry.name = values.contact_name
        _inquiry.organization = values.company_name
        _inquiry.phone = values.phone_number
        _inquiry.email = values.email
        _inquiry.content = values.comments
        _inquiry.source = 'kenko.mx/enterprise'
        _inquiry.status = 'inquiry'
        _inquiry.type_id = 2
        _inquiry.organization_type = values.company_type
        
        // {"inquiry": { "name": "Canek",
        //  "phone": "5543862903", "email": "cnkmor@gmail.com", 
        //  "content": "Necesito información", 
        //  "type_id": 1, "source": "kenko.mx", 
        //  "status": "inquiry"}}

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({inquiry:_inquiry})
        };
        console.log(_inquiry)

        fetch('https://kenko-mkt-services.herokuapp.com/api/v1/inquiries ',requestOptions)
        .then(
            (response) => {
                const data = response.data
                setSended(true)           
            }
        )
  }

  const handleChangeCT= (newValue) => {
    setCompanyType(newValue);
  }



return (
    <div className='p-5 border bg-white border-20 box-shadow'>
        {sended == false ? (
             <Formik
             initialValues={{
                 company_name: '',
                 contact_name: '',
                 organization_type: '',
                 phone_number: '',
                 email: '',
                 comments: '',
     
             }}
             // validationSchema={validation}
             onSubmit={ async (values) => {
                 createEnterprise(values);
             }}
             render = {
                 ({
                     values,
                     setFieldValue,
                     errors
                 }) => (
                 <Form>
                 <div className='row px-3'>
                     <div xs='12' md='12' className='mb-3'>
                     <p className='mb-0 font-weight-bold c-cyan'>Nombre de la organizacion</p>
                     <Field  className={`w-100 c-wedgewood p-2 rounded-10 border-bottom  ${errors.contact_name ? 'border-mandy' : 'border-bottom border-none'}`} name='company_name' /> 
                     
                     {errors.company_name ? (
                         <div className='c-mandy'>{errors.company_name}</div>
                     ) : null}
                     </div>
                     <div xs='12' md='12' className='mb-3'>
                     <p className='mb-0 font-weight-bold c-cyan'>Tipo de Empresa</p>
                     <Field as='select'
                       className={`w-100 c-wedgewood p-2 rounded-10 bg-white ${errors.company_type ? 'border-mandy' : 'border-bottom border-none'}`} name='company_type' >
                         <option value="Farmaceutica">Farmaceutica</option>
                         <option value="Hospital">Hospital</option>
                         <option value="Laboratorio">Laboratorio</option>
                         <option value="Clinica">Clínica</option>
                         <option value="Tecnologia">Tecnologia</option>
                         <option value="Gobierno">Gobierno</option>
                         <option value="Aseguradora">Aseguradora</option>
                         <option value="Otro">Otro</option>
                     </Field>
                     
                     
                     {errors.company_name ? (
                         <div className='c-mandy'>{errors.company_name}</div>
                     ) : null}
                     </div>
                     <div xs='12' md='12' className='mb-3'>
                     <p className='mb-0 font-weight-bold c-cyan'>Nombre del contacto</p>
                     <Field  className={`w-100 c-wedgewood p-2 rounded-10 ${errors.contact_name ? 'border-mandy' : 'border-bottom border-none'}`} name='contact_name' 
                     />
                     {errors.contact_name ? (
                         <div className='c-mandy'>{errors.contact_name}</div>
                     ) : null}
                     </div>
                     <div xs='12' md='6' className='mb-3'>
                         <p className='mb-0 font-weight-bold c-cyan'>Telefono</p>
                         <Field type='text'
                             className={`w-100 c-wedgewood p-2 rounded-10 ${errors.phone_number ? 'border-mandy' : 'border-bottom border-none'}`}
                             name='phone_number' 
                         />
                         {errors.phone_number ? (
                             <div className='c-mandy'>{errors.phone_number}</div>
                         ) : null}
                     </div>
                     <div xs='12' md='6' className='mb-3'>
                         <p className='mb-0 font-weight-bold c-cyan'>Email</p>
                         <Field type='email'  className={`w-100 c-wedgewood p-2 rounded-10 ${errors.email ? 'border-mandy' : 'border-bottom border-none'}`} name='email'/>
                         {errors.email ? (
                             <div className='c-mandy'>{errors.email}</div>
                         ) : null}
                     </div>
                     <div xs='12' md='6' className='mb-3'>
                         <p className='mb-0 font-weight-bold c-cyan'>Comentarios</p>
                         <Field  className={`w-100 c-wedgewood p-2 rounded-10 ${errors.comments ? 'border-mandy' : 'border-bottom border-none'}`} name='comments'
                         setFieldValue=''
                         />
                         {errors.comments ? (
                             <div className='c-mandy'>{errors.comments}</div>
                         ) : null}
                     </div>
                 <div className='col-12'>
                     <button type='submit' className={`border-none py-2 mx-3 font-weight-bold bg-sienna c-white rounded-20 float-end btn-blue mt-3`}>
                         Guardar
                     </button>
                 </div>
                 </div>
                     {/* <button className={`border-none py-2 mx-3 font-weight-bold bg-light-gray c-white rounded-20 btn-orange  mt-3`} >Cancelar</button> */}
                 </Form>
             )}/>
        ):(
            <div className='col-12 py-5'>
                <p className='fs-4 c-cyan'>Espera muy pronto noticias nuestras!<br/> Ya haz sido registrado</p>
                <button type='button' onClick={()=>setSended(false)} className={`border-none py-2 mx-3 fw-bold bg-sienna c-white rounded-20 bg-cyan px-4 mt-3`}>
                     Crear otro Registro
                </button>
            </div>
        )}
       
    </div>
    );
}

export default EnterpriseForm
