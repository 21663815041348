import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import EnterpriseForm from "../components/enterpriseForm"

const Enterprise = () => (
  <Layout>
    {/* <Seo title="Empresas" /> */}
    <div className='mt-5 pb-5 pt-7'>
      <div className='col-12 text-center c-elm px-3'>
        <h1>
        ¿Estás listo para integrar tus servicios al ecosistema de salud digital?
        </h1>
      </div>
    </div>
    <div className='col-12 p-5 text-center bg-elm'>
    <p className='px-5 py-3 fs-normal fs-4 c-white'><span className='c-white fs-2 fw-bold'>Kenko</span> te permite mejorar la experiencia de tus pacientes, <br/>
      unificando su información en su expediente médico electrónico y posicionando 
      tu marca como un actor clave en la transformación digital del cuidado de la salud. </p>
    </div>
    <section className='bg-grayishblue'>
      <div className='col-md-8 mx-auto py-5 bg-stethoscope'>
        <p className='text-center c-cyan fw-bolder fs-4'>Contactanos y descubre los beneficios de integrar tus servicios a <span className='c-olivine fs-2 fw-bold'>Kenko</span> </p>
        <EnterpriseForm/>
      </div>
    </section>
  </Layout>
)

export default Enterprise
